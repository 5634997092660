import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  //Orders
  {
    path: '/orders',
    name: 'Orders',
    component: () => import('../views/order/Orders.vue')
  },
  {
    path: '/order/create',
    name: 'CreateOrder',
    component: () => import('../views/order/CreateOrder.vue')
  },
  {
    path: '/order/complete/:id',
    name: 'CompleteOrder',
    component: () => import('../views/order/CompleteOrder.vue')
  },
  {
    path: '/order/:id',
    name: 'Order',
    component: () => import('../views/order/Order.vue')
  },

  //Invoices
  {
    path: '/invoices',
    name: 'Invoices',
    component: () => import('../views/invoice/Invoices.vue')
  },

  //Seats
  {
    path: '/seats',
    name: 'Seats',
    component: () => import('../views/seats/Seats.vue')
  },

  //Takeout
  {
    path: '/takeout',
    name: 'Takeout',
    component: () => import('../views/takeout/TakeoutRecord.vue')
  },
  
  //Balance
  {
    path: '/balance',
    name: 'Balance',
    component: () => import('../views/balance/Balance.vue')
  },
  //Record
  {
    path: '/record',
    name: 'Record',
    component: () => import('../views/record/Record.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
